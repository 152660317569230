<template>
  <div>
    <template v-if="!route.query.authentication_code">
      <div class="row">
        <div :class="pageColumns">
          <h1 class="big text-weight-bold q-mt-none q-mb-lg">
            {{ $t('common.welcome') }}
          </h1>
          <page-title
            :title="$t('account.signIn.title')"
            :subtitle="$t('account.signIn.subtitle')"
          />
          <!-- PROVIDERS SIGN IN -->
          <error-catcher
            :not-displayed="[
              'email',
              'recaptcha_result',
              'providerLogin',
              'authentication_code',
            ]"
            class="q-mb-md q-mt-none"
          />

          <form-general-error error-type="providerLogin" class="q-mb-md" />

          <google-connect-btn />

          <div class="row q-col-gutter-x-lg q-my-md">
            <div class="col-auto">
              <h5 class="text-uppercase text-muted q-ma-none">
                {{ $t('common.or') }}
              </h5>
            </div>
            <div class="col column justify-center">
              <q-separator size="2px" />
            </div>
          </div>

          <!-- Mail SIGN IN -->
          <form-general-error
            error-type="authentication_code"
            class="q-mb-md"
          />
          <form-with-recaptcha
            v-model="recaptcha_result"
            :btn-label="$t('account.signIn.sendCode')"
            :submit-fct="handleConnect"
          >
            <!-- Mail -->
            <q-input
              v-model="email"
              type="email"
              :label="$t('common.mail')"
              autofocus
              data-test="email"
              :error="v$.email.$error || credentialError"
              :error-message="
                v$.email.$errors.length
                  ? (v$.email.$errors[0].$message as string)
                  : ''
              "
              @focus="resetError('email')"
              @blur="v$.email.$touch"
            />
          </form-with-recaptcha>
        </div>
      </div>
    </template>
    <!-- LOADING -->
    <template v-else>
      <div class="row justify-center">
        <div class="col-auto">
          <q-circular-progress indeterminate color="primary" size="lg" />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useMeta, useQuasar } from 'quasar'

import { useAuthStore } from 'stores/receiver/account/auth/auth'
import { useErrorStore } from 'stores/common/error'

import { useValidationRules } from 'composables/common/form/validationRules'
import { pageColumns } from 'composables/receiver/config/configCss'

import PageTitle from 'components/common/ui/pageElements/PageTitle.vue'
import GoogleConnectBtn from 'components/receiver/account/common/GoogleConnectBtn.vue'
import FormWithRecaptcha from 'components/common/ui/form/recaptcha/FormWithRecaptcha.vue'
import FormGeneralError from 'components/common/ui/form/FormGeneralError.vue'
import ErrorCatcher from 'components/common/ui/form/ErrorCatcher.vue'

const route = useRoute()
const router = useRouter()
const { t } = useI18n()
const { localStorage } = useQuasar()
const { vuelidateInit, isRequired, maxCharacters, isEmail } =
  useValidationRules()

const authStore = useAuthStore()
const errorStore = useErrorStore()

onBeforeMount(() => {
  if (route.query.redirect) {
    localStorage.set('redirect', route.query.redirect)
    // Remove the redirect when used
    const query = { ...route.query }
    delete query.redirect
    router.push({ path: route.path, query })
  }
})

// SIGN IN WITH CREDENTIALS
const email = ref('')

// ReCaptcha
const recaptcha_result = ref('')

// VALIDATION
const credentialError = ref(false)

const resetError = (type: string) => {
  errorStore.removeError(type)
  credentialError.value = false
}

const rules = {
  email: {
    required: isRequired(t('common.mail')),
    email: isEmail,
    maxCharacters: maxCharacters(70),
  },
}
const v$ = vuelidateInit(rules, { email })

const handleConnect = async () => {
  const valid = await v$.value.$validate()
  if (valid) {
    const res = await authStore.preAuthWithCredentials({
      email: email.value,
      recaptcha_result: recaptcha_result.value,
      successful_signup_url: `${window.location.origin}/login?signup=success`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || null,
    })
    if (res && res.status === 200) {
      router.push({
        path: '/login/check',
        query: {
          code: res.payload.pre_authentication_code,
          method: res.payload.method,
          mail: res.payload.email,
        },
      })
      return true
    } else if (res && res.status === 201) {
      router.push({
        path: '/sign-up',
        query: {
          digits_identifier: res.payload.digits_identifier,
          mail: email.value,
        },
      })
      return true
    }
  }
  return false
}

useMeta({
  title: `WeTransform - ${t('router.login')}`,
})
</script>
