<template>
  <div class="q-gutter-md">
    <q-slide-transition
      v-for="property in displayedErrors"
      :key="property"
      appear
    >
      <icon-card
        icon="fa-solid fa-triangle-exclamation"
        icon-size="sm"
        :center="false"
        class="text-negative animated"
        :style="{ borderColor: 'var(--q-negative)' }"
        bordered
        data-test="error-catcher"
      >
        <div class="flex items-center full-height">
          {{ `${property}: ${errorStore.backErrors[property]}` }}
        </div>
      </icon-card>
    </q-slide-transition>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useErrorStore } from 'stores/common/error'

import IconCard from 'components/common/ui/card/IconCard.vue'

const errorStore = useErrorStore()

// PROPS
const props = defineProps<{
  notDisplayed?: string[]
}>()

const notDisplayedErrors = computed(() => {
  return props.notDisplayed
    ? [...props.notDisplayed, 'generalError']
    : ['generalError']
})

const displayedErrors = computed(() => {
  return Object.keys(errorStore.backErrors).filter(
    (property) => !notDisplayedErrors.value.some((el) => el.includes(property))
  )
})
</script>
